.MuiOutlinedInput-root fieldset {
    border-color: #eaf9f9;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #eaf9f9 !important;
}

.MuiOutlinedInput-root:hover fieldset {
    border-color: #eaf9f9 !important;
}