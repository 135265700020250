.MuiOutlinedInput-root fieldset {
    border-color: #eaf9f9;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #eaf9f9 !important;
}

.MuiOutlinedInput-root:hover fieldset {
    border-color: #eaf9f9 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px !important;
}

.select2-container .select2-selection--single {
    height: 50px !important;
}

.select2-container--default .select2-selection--single {
    background-color: #E6E7E8 !important;
    border: 1px solid #E6E7E8 !important;
    border-radius: 60px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px !important;
    right: 10px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    line-height: 30px !important;
}

.select2-container .select2-selection--multiple {
    height: 50px !important;
}

.select2-container--default .select2-selection--multiple {
    background-color: #E6E7E8 !important;
    border: 1px solid #E6E7E8 !important;
    border-radius: 60px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow {
    top: 10px !important;
    right: 10px !important;
}

ul.select2-selection__rendered {
    padding-left: 20px!important;
    margin-top: 3px!important;
}

input.select2-search__field {
    margin-top: 10px!important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: 15px !important;
    background-color: #e0e0e0 !important;
    border: 1px solid #e0e0e0 !important;
}