body {
  /* background-color: #76ebf4; */
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: white;
}

.MuiOutlinedInput-input {
  color: rgb(91 84 84) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #76ebf4 !important;
}
.MuiInputBase-root {
  font-family: inherit !important;
}

.MuiTypography-h6 {
  font-family: inherit !important;
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}
.custom-btn-primary {
  width: 100%;
  background-color: #76ebf4 !important;
  font-weight: 600 !important;
}

.custom-btn-primary:hover {
  background-color: #76ebf4 !important;
}

.custom-btn-secondary {
  width: 100%;
  background-color: #e6e7e8 !important;
  color: #7cd1dc !important;
  border: #7cd1dc solid 2px !important;
  font-weight: 600 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.custom-btn-secondary:hover {
  background-color: #ffffff !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.custom-btn-secondary-selected {
  width: 100%;
  background-color: #eaf9f9 !important;
  color: #76ebf4 !important;
  border: #76ebf4 solid 2px !important;
  font-weight: 600 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.custom-btn-secondary-selected:hover {
  background-color: #eaf9f9 !important;
}

.typography-with-icon {
  display: flex;
  justify-content: space-between;
}

.menu > li:hover {
  background-color: lightgray;
}

.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 60px !important;
  background-color: #e6e7e8 !important;
}

.MuiInputBase-input {
  font-family: "Bai Jamjuree", sans-serif !important;
}

.select-btn-secondary {
  width: 100%;
  background-color: #ffffff !important;
  color: #5f6f70 !important;
  border: #808080 solid 1px !important;
  font-weight: 600 !important;
}

span.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: auto !important;
}

.cls-1 {
  fill: #58595b;
}