.custom-btn-primary {
  width: 100%;
  background-color: #76ebf4 !important;
  font-weight: 600 !important;
}

.custom-btn-primary:hover {
  background-color: #76ebf4 !important;
}

.custom-btn-secondary {
  width: 100%;
  background-color: #ffffff !important;
  color: #5f6f70 !important;
  border: #5f6f70 solid 0px !important;
  font-weight: 600 !important;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  text-transform: none;
}

.custom-btn-secondary:hover {
  background-color: #ffffff !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.custom-btn-secondary-selected {
  width: 100%;
  background-color: #e3fbfc !important;
  color: #5f6f70 !important;
  border: #5f6f70 solid 1px !important;
  font-weight: 600 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-transform: none;
}

.custom-btn-secondary-selected:hover {
  background-color: #eaf9f9 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
