body {
  margin: 0;
  font-family: "Regular", "Medium", "SemiBold", "Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Regular";
}

@font-face {
  font-family: "Regular";
  src: local("BaiJamjuree-Regular"),
    url("./fonts/BaiJamjuree-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("BaiJamjuree-Medium"),
    url("./fonts/BaiJamjuree-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: local("BaiJamjuree-SemiBold"),
    url("./fonts/BaiJamjuree-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("BaiJamjuree-Bold"),
    url("./fonts/BaiJamjuree-Bold.ttf") format("truetype");
}


.MuiInput-underline::after {
  border-bottom: 1px solid  #757B8A !important;
}
.MuiInput-underline:before {
  border-bottom: 1px solid  #757B8A !important;
}